import '../styles/style.scss';

document.querySelector('.lang-selector select').addEventListener('change', function (event) {
    const lang = event.currentTarget.value;
    const pathname = window.location.pathname.replace(/^\/(ru|uk)/, '');

    if (lang === 'en') {
        window.location.href = `${pathname}`;
    } else {
        window.location.href = `/${lang}${pathname}`;
    }
});

var countryTableSelectSmall = document.querySelector('.compareTable .selector--small select');
var countryTableSelectBig = document.querySelector('.compareTable .selector--big select');
var compareTable = document.querySelector('.compareTable table');

if (countryTableSelectSmall && compareTable) {
    countryTableSelectSmall.addEventListener('change', function () {
        var selectedIndex = this.selectedIndex + 2;

        compareTable.className = compareTable.className.replace(/show-small-\d+/, 'show-small-' + selectedIndex);
    });
}

if (countryTableSelectBig && compareTable) {
    countryTableSelectBig.addEventListener('change', function () {
        var selectedIndex = this.selectedIndex + 1;
        
        compareTable.className = compareTable.className.replace(/show-big-\d+/, 'show-big-' + selectedIndex);
    });
}